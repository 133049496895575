import React from "react";
import image from "../../pages/pageComponents/assets/image.webp";
import { useNavigate } from "react-router-dom";
import ScholarApp from '../headerComponents/assets/Scholar App.webp';
import InstituteApp from '../headerComponents/assets/Institute App.webp';
import MentorApp from '../headerComponents/assets/Mentor App.jpg';

const HeaderMenu = ({ display }) => {
  const navigate = useNavigate();

  const handleScholarApp = () => {
    navigate("/products#ScholarApp");
  };

  const handleMentorApp = () => {
    navigate("/products#MentorApp");
  };

  const handleInstituteApp = () => {
    navigate("/products#InstituteApp");
  };

  const handleSolutionClick = (solutionName) => {
    navigate(`/products#Solutions-${solutionName}`);
  };

  const handleClick = () => {
    navigate("/book-demo");
  };

  const handleProducts = () => {
    navigate("/products");
  };

  return (
    <>
      <div style={{ display: display ? 'block' : 'none' }} className="menu-item-nav">
        <div className="d-flex">
          <div className="margin-nav-items margin-left-30 min-width-200">
            <h3>Products</h3>
            <div className="d-flex flex-column gap-3">
              <div>
                <div className="d-flex gap-3">
                  <div className="Icon-nav-bar"><img src={ScholarApp} alt="scholar app" style={{maxWidth:'30px'}}/></div>
                  <div
                    className="d-flex flex-column gap-1 hover-item-nav-menu"
                    onClick={handleScholarApp}
                  >
                    <h4>Scholar App</h4>
                    <div className="p-menu">For Students & Parents</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex gap-3">
                  <div className="Icon-nav-bar"><img src={MentorApp} alt="Mentor app" style={{maxWidth:'35px', borderRadius:'100px'}}/></div>
                  <div
                    className="d-flex flex-column gap-1 hover-item-nav-menu"
                    onClick={handleMentorApp}
                  >
                    <h4>Mentor App</h4>
                    <div className="p-menu">For Teachers & Professors</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex gap-3">
                  <div className="Icon-nav-bar"><img src={InstituteApp} alt="Mentor app" style={{maxWidth:'35px', borderRadius:'100px'}}/></div>
                  <div
                    className="d-flex flex-column gap-1 hover-item-nav-menu"
                    onClick={handleInstituteApp}
                  >
                    <h4>Institute App</h4>
                    <div className="p-menu">For Educational Institute</div>
                  </div>
                </div>
              </div>
              <div
                className="p-menu second-color cursor-pointer mt-3"
                onClick={handleProducts}
              >
                <u> View All Products & Features</u>
              </div>
            </div>
          </div>
          <div className="margin-nav-items min-width-200">
            <h3>Solutions</h3>
            <div className="d-flex flex-column gap-3">
              <div>
                <div className="d-flex gap-3">
                  <div
                    className="d-flex flex-column gap-1 hover-item-nav-menu"
                    onClick={() => handleSolutionClick("Financial Management")}
                  >
                    <h4>Financial Management</h4>
                    <div className="p-menu">Management Solution</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex gap-3">
                  <div
                    className="d-flex flex-column gap-1 hover-item-nav-menu"
                    onClick={() => handleSolutionClick("Human Resource Management")}
                  >
                    <h4>HR Management</h4>
                    <div className="p-menu">Management Solution</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex gap-3">
                  <div
                    className="d-flex flex-column gap-1 hover-item-nav-menu"
                    onClick={() => handleSolutionClick("Employee Efficiency Tracking")}
                  >
                    <h4>Employee Tracking</h4>
                    <div className="p-menu">Tracking Systems</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex gap-3">
                  <div
                    className="d-flex flex-column gap-1 hover-item-nav-menu"
                    onClick={() => handleSolutionClick("Parent Engagement App")}
                  >
                    <h4>Parent Engagement App</h4>
                    <div className="p-menu">Application</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex gap-3">
                  <div
                    className="d-flex flex-column gap-1 hover-item-nav-menu"
                    onClick={() => handleSolutionClick("Real-time Monitoring")}
                  >
                    <h4>Real-time Monitoring</h4>
                    <div className="p-menu">Automation</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex gap-3">
                  <div
                    className="d-flex flex-column gap-1 hover-item-nav-menu"
                    onClick={() => handleSolutionClick("Compliance Management")}
                  >
                    <h4>Compliance Management</h4>
                    <div className="p-menu">Management Solution</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex gap-3">
                  <div
                    className="d-flex flex-column gap-1 hover-item-nav-menu"
                    onClick={() => handleSolutionClick("AI-Powered Features")}
                  >
                    <h4>AI-Powered Features</h4>
                    <div className="p-menu">AI-Powered Features</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-menu-nav-item">
            <img className="col-featured__img" src={image} alt="img" />
            <div className="d-flex flex-column m-4">
              <h3>Our Vision</h3>
              <div className="p-menu mb-4">
                To be at the forefront of educational transformation, making
                personalized, AI-driven learning accessible to every student
                around the globe by 2044.
              </div>
              <div className="mb-4">
                <div
                  className="p-menu second-color cursor-pointer"
                  onClick={handleClick}
                >
                  Discover what School.ai can do!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderMenu;
