import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import check from "./assets/check.svg";
import { Link } from "react-router-dom";

function Price() {
  return (
    <>
      <Row className="component-bottom-margin">
        <Col xs={12} md={4} className="d-flex justify-content-center p-3 h-100">
          <div className="price-section">
            <div>
              <div className="p-4">
                <div>
                  <p className="subheading-price mb-1">Engage & Excel</p>
                  <h2>Essential</h2>
                  <p className="tier-desc">
                    The Essential plan helps students integrate courses, track
                    records, and manage grades. It includes a basic institution
                    app and 24/7 support to keep students engaged and excelling.
                  </p>
                </div>
                {/* <div>
                  <p className="eyebrow-text">Starting at</p>
                  <p className="d-flex align-items-center gap-2">
                    <span className="price">$25</span>/month
                  </p>
                  <p
                    className="d-flex align-items-center gap-2"
                    style={{ marginTop: "-25px" }}
                  >
                    <span className="price">$200</span>/year{" "}
                    <span
                      style={{
                        background: "#5ECC70",
                        borderRadius: "200px",
                        padding: "5px 10px",
                        color: "white",
                        fontWeight: "600",
                        marginLeft: "10px",
                      }}
                    >
                      Save 20%
                    </span>
                  </p>
                  <p className="p-small">Based on a 2 year contract</p>
                </div> */}
                <Link to="/book-demo">
                  <button className="price-button">Free Demo</button>
                </Link>
              </div>
            </div>
            <div className="feature-row learning-mgmt p-4">
              <h4 className="mb-4">Features</h4>
              <ul className="d-flex flex-column gap-3 list-style-none">
                <li>
                  <img
                    src={check}
                    alt="image1"
                    className="img-fluid image-shadow-1 px-1"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  Course Integration
                </li>
                <li>
                  <img
                    src={check}
                    alt="image1"
                    className="img-fluid image-shadow-1 px-1"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  Student Track Record
                </li>
                <li>
                  <img
                    src={check}
                    alt="image1"
                    className="img-fluid image-shadow-1 px-1"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  Grading Management
                </li>
                <li>
                  <img
                    src={check}
                    alt="image1"
                    className="img-fluid image-shadow-1 px-1"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  Basic institution App
                </li>
                <li>
                  <img
                    src={check}
                    alt="image1"
                    className="img-fluid image-shadow-1 px-1"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  24/7 Support
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col xs={12} md={4} className="d-flex justify-content-center p-3 h-100">
          <div className="price-section">
            <div>
              <div className="p-4">
                <div>
                  <p className="subheading-price mb-1">Develop & Deliver</p>
                  <h2>Advanced</h2>
                  <p className="tier-desc">
                    The Advanced plan adds mentor and scholar tools to the
                    Essential features. It includes a Mentor app, Scholar app,
                    and basic institution app, ideal for enhancing educational
                    delivery.
                  </p>
                </div>
                {/* <div>
                  <p className="eyebrow-text">Starting at</p>
                  <p className="d-flex align-items-center gap-2">
                    <span className="price">$25</span>/month
                  </p>
                  <p
                    className="d-flex align-items-center gap-2"
                    style={{ marginTop: "-25px" }}
                  >
                    <span className="price">$200</span>/year
                    <span
                      style={{
                        background: "#5ECC70",
                        borderRadius: "200px",
                        padding: "5px 10px",
                        color: "white",
                        fontWeight: "600",
                        marginLeft: "10px",
                      }}
                    >
                      Save 20%
                    </span>
                  </p>

                  <p className="p-small">Based on a 2 year contract</p>
                </div> */}
                <Link to="/book-demo">
                  <button className="price-button">Free Demo</button>
                </Link>
              </div>
            </div>
            <div className="feature-row learning-mgmt p-4">
              <h4 className="mb-4">Features</h4>
              <ul className="d-flex flex-column gap-3 list-style-none">
                <li>
                  <img
                    src={check}
                    alt="image1"
                    className="img-fluid image-shadow-1 px-1"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  Essential Plan Included
                </li>
                <li>
                  <img
                    src={check}
                    alt="image1"
                    className="img-fluid image-shadow-1 px-1"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  Mentor App
                </li>
                <li>
                  <img
                    src={check}
                    alt="image1"
                    className="img-fluid image-shadow-1 px-1"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  Scholar App
                </li>
                <li>
                  <img
                    src={check}
                    alt="image1"
                    className="img-fluid image-shadow-1 px-1"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  Basic institution App
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col xs={12} md={4} className="d-flex justify-content-center p-3 h-100">
          <div className="price-section">
            <div>
              <div className="p-4">
                <div>
                  <p className="subheading-price mb-1">Manage & Optimize</p>
                  <h2>Ultimate</h2>
                  <p className="tier-desc">
                    The Ultimate plan provides comprehensive management for
                    enterprise institutions. It includes all Essential and
                    Advanced features, advanced apps, and AI integration,
                    optimizing the educational ecosystem.
                  </p>
                </div>
                {/* <div>
                  <p className="eyebrow-text">Starting at</p>
                  <p className="d-flex align-items-center gap-2">
                    <span className="price">$25</span>/month
                  </p>
                  <p
                    className="d-flex align-items-center gap-2"
                    style={{ marginTop: "-25px" }}
                  >
                    <span className="price">$200</span>/year
                    <span
                      style={{
                        background: "#5ECC70",
                        borderRadius: "200px",
                        padding: "5px 10px",
                        color: "white",
                        fontWeight: "600",
                        marginLeft: "10px",
                      }}
                    >
                      Save 20%
                    </span>
                  </p>
                  <p className="p-small">Based on a 2 year contract</p>
                </div> */}
                <Link to="/book-demo">
                  <button className="price-button">Free Demo</button>
                </Link>
              </div>
            </div>
            <div className="feature-row learning-mgmt p-4">
              <h4 className="mb-4">Features</h4>
              <ul className="d-flex flex-column gap-3 list-style-none">
                <li>
                  <img
                    src={check}
                    alt="image1"
                    className="img-fluid image-shadow-1 px-1"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  Basic & Advanced Features
                </li>
                <li>
                  <img
                    src={check}
                    alt="image1"
                    className="img-fluid image-shadow-1 px-1"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  Advanced Scholar App
                </li>
                <li>
                  <img
                    src={check}
                    alt="image1"
                    className="img-fluid image-shadow-1 px-1"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  Advanced Menotor App
                </li>
                <li>
                  <img
                    src={check}
                    alt="image1"
                    className="img-fluid image-shadow-1 px-1"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  Advanced institution App
                </li>
                <li>
                  <img
                    src={check}
                    alt="image1"
                    className="img-fluid image-shadow-1 px-1"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  AI Integration
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mx-1 component-bottom-margin-2">
        <div className="special-bg px-3">
          <h3 className="text-center size-40 weight-700 second-color margin-1 line-height-120">
            Not Sure Which Plan to Choose?
          </h3>
          <div className="d-flex justify-content-center align-items-center text-color size-18 margin-2">
            <p className="text-center" style={{ maxWidth: "800px" }}>
              Our dedicated team is ready to help you select the plan that best
              suits your needs. Contact us for more details or to schedule a
              personalized demo.
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <a
              href="https://calendly.com/scool_ai/30min"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="main-button-reverse weight-600">
                Book 30 Mintutes Meeting
              </button>
            </a>
          </div>
        </div>
      </Row>
    </>
  );
}

export default Price;
