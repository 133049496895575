// api/postsApi.js
import axios from 'axios';

const BASE_URL = 'https://unsere-kinder-pesh-town.herokuapp.com/scoolAi';

export const createDemo = async (body) => {
    try {
        const response = await axios.post(`${BASE_URL}/create-demo`, body);
        return response.data;
    } catch (error) {
        throw error;
    }
};
