import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Col, Form, OverlayTrigger, Tooltip, Row } from "react-bootstrap";
import Logos from "./pageComponents/logos";
import {
  IconArrowBadgeRightFilled,
  IconPlus,
  IconX,
  IconInfoCircle,
} from "@tabler/icons-react";
import { getNames } from "country-list";
import { createDemo } from "../../api";
import Trial from "./pageComponents/assets/trial.png";
import { toast } from "react-toastify";

function TrialAccount() {
  const [errors, setErrors] = useState({});
  const countries = getNames();

  const [ageGroup, setAgeGroup] = useState([]);
  const [currentAgeGroup, setCurrentAgeGroup] = useState("");

  const [classGroup, setClassGroup] = useState([]);
  const [currentClassGroup, setCurrentClassGroup] = useState("");

  // const [employeeStatus, setEmployeeStatus] = useState([]);
  // const [currentEmployeeStatus, setCurrentEmployeeStatus] = useState("");

  const [employeeTitle, setEmployeeTitle] = useState([]);
  const [currentEmployeeTitle, setCurrentEmployeeTitle] = useState("");

  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    schoolName: "",
    schoolWebsite: "",
    title: "",
    address: "",
    country: "",
    type: "Trial",
  });

  const handleArrays = (e) => {
    switch (e.target.name) {
      case "ageGroup":
        setCurrentAgeGroup(e.target.value);
        break;
      case "classGroup":
        setCurrentClassGroup(e.target.value);
        break;
      // case "employeeStatus":
      //   setCurrentEmployeeStatus(e.target.value);
      //   break;
      case "employeeTitle":
        setCurrentEmployeeTitle(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleAddArray = (name) => {
    let data, add;
    switch (name) {
      case "ageGroup":
        data = currentAgeGroup
          .split(",")
          .map((item) => item.trim())
          .filter((item) => item);
        add = new Set([...ageGroup, ...data]);
        setAgeGroup([...add]);
        setCurrentAgeGroup("");
        break;
      case "classGroup":
        data = currentClassGroup
          .split(",")
          .map((item) => item.trim())
          .filter((item) => item);
        add = new Set([...classGroup, ...data]);
        setClassGroup([...add]);
        setCurrentClassGroup("");
        break;
      // case "employeeStatus":
      //   data = currentEmployeeStatus
      //     .split(",")
      //     .map((item) => item.trim())
      //     .filter((item) => item);
      //   add = new Set([...employeeStatus, ...data]);
      //   setEmployeeStatus([...add]);
      //   setCurrentEmployeeStatus("");
      //   break;
      case "employeeTitle":
        data = currentEmployeeTitle
          .split(",")
          .map((item) => item.trim())
          .filter((item) => item);
        add = new Set([...employeeTitle, ...data]);
        setEmployeeTitle([...add]);
        setCurrentEmployeeTitle("");
        break;
      default:
        break;
    }
  };

  const handleRemoveArray = (index, name) => {
    let data;
    switch (name) {
      case "ageGroup":
        data = [...ageGroup];
        data.splice(index, 1);
        setAgeGroup(data);
        break;
      case "classGroup":
        data = [...classGroup];
        data.splice(index, 1);
        setClassGroup(data);
        break;
      // case "employeeStatus":
      //   data = [...employeeStatus];
      //   data.splice(index, 1);
      //   setEmployeeStatus(data);
      //   break;
      case "employeeTitle":
        data = [...employeeTitle];
        data.splice(index, 1);
        setEmployeeTitle(data);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const trial = {
        ...formData,
        ageGroup,
        classGroup,
        // employeeStatus,
        employeeTitle,
      };
      try {
        const responseData = await createDemo(trial);
        toast.success(responseData.message);
      } catch (error) {
        if (error.response && error.response.status === 409) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error 404");
        }
      }
    }
  };

  const validateForm = () => {
    const {
      email,
      firstName,
      lastName,
      schoolName,
      title,
      schoolWebsite,
      country,
    } = formData;
    const newErrors = {};

    // Business email validation
    // const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // if (!email || !emailPattern.test(email) || email.endsWith("gmail.com")) {
    //   newErrors.email = "Please enter a valid business email address.";
    // }

    if (!email) newErrors.email = "First name is required!";
    if (!firstName) newErrors.firstName = "First name is required!";
    if (!lastName) newErrors.lastName = "Last name is required!";
    if (!schoolName) newErrors.schoolName = "School name is required!";
    if (!schoolWebsite) newErrors.schoolWebsite = "School website is required!";
    if (!country) newErrors.country = "Please select a country.";
    if (!title) newErrors.title = "Please add your title!";
    if (classGroup.length === 0) {
      newErrors.classGroup = "class Group are required!";
    }
    if (employeeTitle.length === 0) {
      newErrors.employeeTitle = "Employee Titles are required!";
    }
    // if (employeeStatus.length === 0) {
    //   newErrors.employeeStatus = "Employee Status are required!";
    // }
    if (ageGroup.length === 0) {
      newErrors.ageGroup = "Age Groups are required!";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <>
      <div className="book-demo-body p-5 component-bottom-margin custom-padding-resp">
        <Container fluid="xxl">
          <Row>
            <Col xs={12} md={7}>
              <div className="py-5 px-2 font-white">
                <p className="d-flex hero-font weight-700">
                  Experience the Future of Education.
                </p>
                <p className="size-18">
                  Unlock the potential of your educational institution with our
                  cutting-edge, personalized learning solutions powered by
                  advanced AI.
                </p>

                <li style={{ color: "white" }}>
                  {" "}
                  <IconArrowBadgeRightFilled style={{ color: "white" }} />
                  Seamlessly integrate with your existing school stack for a
                  cohesive learning experience within your organization.
                </li>
                <li style={{ color: "white" }}>
                  {" "}
                  <IconArrowBadgeRightFilled style={{ color: "white" }} />
                  Elevate retention rates, boost productivity, and drive revenue
                  growth—backed by actionable insights and analytics.
                </li>
                <li style={{ color: "white" }}>
                  {" "}
                  <IconArrowBadgeRightFilled style={{ color: "white" }} />
                  Trial Account will be valid for 15 days.
                </li>
              </div>{" "}
              <img
                src={Trial}
                alt="Introduction to SCool.Ai"
                className="img-fluid image-shadow-1"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Col>
            <Col xs={12} md={5}>
              <div className="demo-form">
                <p className="text-center size-30 second-color weight-700 mb-5">
                  Start your Trial
                </p>

                <Form className="demo-form-data" onSubmit={handleSubmit}>
                  <Row className="m-3">
                    <Col xs={12}>
                      <Form.Group className="mb-4" controlId="email">
                        <Form.Label className="label-form">
                          Business Email <span className="label-star">*</span>
                        </Form.Label>
                        <Form.Control
                          className="size-16 py-3"
                          size="lg"
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4" controlId="firstName">
                        <Form.Label className="label-form">
                          First Name <span className="label-star">*</span>
                        </Form.Label>
                        <Form.Control
                          className="size-16 py-3"
                          size="lg"
                          type="text"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                          isInvalid={!!errors.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4" controlId="lastName">
                        <Form.Label className="label-form">
                          Last Name <span className="label-star">*</span>
                        </Form.Label>
                        <Form.Control
                          className="size-16 py-3"
                          size="lg"
                          type="text"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                          isInvalid={!!errors.lastName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Group className="mb-4" controlId="schoolName">
                        <Form.Label className="label-form">
                          School Name <span className="label-star">*</span>
                        </Form.Label>
                        <Form.Control
                          className="size-16 py-3"
                          size="lg"
                          type="text"
                          name="schoolName"
                          value={formData.schoolName}
                          onChange={handleChange}
                          isInvalid={!!errors.schoolName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.schoolName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Group className="mb-4" controlId="title">
                        <Form.Label className="label-form">
                          Your Title <span className="label-star">*</span>
                        </Form.Label>
                        <Form.Control
                          className="size-16 py-3"
                          size="lg"
                          type="text"
                          name="title"
                          value={formData.title}
                          onChange={handleChange}
                          isInvalid={!!errors.title}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.title}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Group className="mb-4" controlId="schoolWebsite">
                        <Form.Label className="label-form">
                          School Website <span className="label-star">*</span>
                        </Form.Label>
                        <Form.Control
                          className="size-16 py-3"
                          size="lg"
                          type="text"
                          name="schoolWebsite"
                          value={formData.schoolWebsite}
                          onChange={handleChange}
                          isInvalid={!!errors.schoolWebsite}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.schoolWebsite}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} className="mb-4">
                      <Form.Group controlId="employeeTitle">
                        <Form.Label className="label-form">
                          Employee Titles{" "}
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip-age-group">
                                This is the Employee Titles information.
                              </Tooltip>
                            }
                          >
                            <IconInfoCircle className="icon-tooltip" />
                          </OverlayTrigger>
                        </Form.Label>
                        <div className="d-flex gap-2 align-items-center">
                          <Form.Control
                            className={`size-16 py-3 ${
                              errors.employeeTitle ? "is-invalid" : ""
                            }`}
                            size="lg"
                            type="text"
                            name="employeeTitle"
                            value={currentEmployeeTitle}
                            onChange={handleArrays}
                            placeholder="Add comma separated groups"
                          />
                          <button
                            type="button"
                            className="plus-button"
                            onClick={() => handleAddArray("employeeTitle")}
                          >
                            <IconPlus className="icon-plus" />
                          </button>
                        </div>
                        <Form.Control.Feedback
                          type="invalid"
                          className="d-block"
                        >
                          {errors.employeeTitle}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div
                        className="d-flex gap-2"
                        style={{ flexWrap: "wrap" }}
                      >
                        {employeeTitle.map((item, index) => (
                          <div key={index} className="list-spans">
                            <span className="font-list">{item}</span>
                            <IconX
                              className="icon-x-list"
                              onClick={() =>
                                handleRemoveArray(index, "employeeTitle")
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </Col>
                    {/**<Col xs={12} className="mb-4">
                      <Form.Group controlId="employeeStatus">
                        <Form.Label className="label-form">
                          Employees Status{" "}
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip-age-group">
                                This is the Employee Status information.
                              </Tooltip>
                            }
                          >
                            <IconInfoCircle className="icon-tooltip" />
                          </OverlayTrigger>
                        </Form.Label>
                        <div className="d-flex gap-2 align-items-center">
                          <Form.Control
                            className={`size-16 py-3 ${
                              errors.employeeStatus ? "is-invalid" : ""
                            }`}
                            size="lg"
                            type="text"
                            name="employeeStatus"
                            value={currentEmployeeStatus}
                            onChange={handleArrays}
                            placeholder="Add comma separated groups"
                          />
                          <button
                            type="button"
                            className="plus-button"
                            onClick={() => handleAddArray("employeeStatus")}
                          >
                            <IconPlus className="icon-plus" />
                          </button>
                        </div>
                        <Form.Control.Feedback
                          type="invalid"
                          className="d-block"
                        >
                          {errors.employeeStatus}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div
                        className="d-flex gap-2"
                        style={{ flexWrap: "wrap" }}
                      >
                        {employeeStatus.map((item, index) => (
                          <div key={index} className="list-spans">
                            <span className="font-list">{item}</span>
                            <IconX
                              className="icon-x-list"
                              onClick={() =>
                                handleRemoveArray(index, "employeeStatus")
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </Col>**/}
                    <Col xs={12} className="mb-4">
                      <Form.Group controlId="classGroup">
                        <Form.Label className="label-form">
                          Class Group{" "}
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip-age-group">
                                This is the age class Group information.
                              </Tooltip>
                            }
                          >
                            <IconInfoCircle className="icon-tooltip" />
                          </OverlayTrigger>
                        </Form.Label>
                        <div className="d-flex gap-2 align-items-center">
                          <Form.Control
                            className={`size-16 py-3 ${
                              errors.classGroup ? "is-invalid" : ""
                            }`}
                            size="lg"
                            type="text"
                            name="classGroup"
                            value={currentClassGroup}
                            onChange={handleArrays}
                            placeholder="Add comma separated groups"
                          />
                          <button
                            type="button"
                            className="plus-button"
                            onClick={() => handleAddArray("classGroup")}
                          >
                            <IconPlus className="icon-plus" />
                          </button>
                        </div>
                        <Form.Control.Feedback
                          type="invalid"
                          className="d-block"
                        >
                          {errors.classGroup}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div
                        className="d-flex gap-2"
                        style={{ flexWrap: "wrap" }}
                      >
                        {classGroup.map((item, index) => (
                          <div key={index} className="list-spans">
                            <span className="font-list">{item}</span>
                            <IconX
                              className="icon-x-list"
                              onClick={() =>
                                handleRemoveArray(index, "classGroup")
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Col xs={12} className="mb-4">
                      <Form.Group controlId="ageGroup">
                        <Form.Label className="label-form">
                          Age Group{" "}
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip-age-group">
                                This is the age group information.
                              </Tooltip>
                            }
                          >
                            <IconInfoCircle className="icon-tooltip" />
                          </OverlayTrigger>
                        </Form.Label>
                        <div className="d-flex gap-2 align-items-center">
                          <Form.Control
                            className={`size-16 py-3 ${
                              errors.ageGroup ? "is-invalid" : ""
                            }`}
                            size="lg"
                            type="text"
                            name="ageGroup"
                            value={currentAgeGroup}
                            onChange={handleArrays}
                            placeholder="Add comma separated groups"
                          />
                          <button
                            type="button"
                            className="plus-button"
                            onClick={() => handleAddArray("ageGroup")}
                          >
                            <IconPlus className="icon-plus" />
                          </button>
                        </div>
                        <Form.Control.Feedback
                          type="invalid"
                          className="d-block"
                        >
                          {errors.ageGroup}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div
                        className="d-flex gap-2"
                        style={{ flexWrap: "wrap" }}
                      >
                        {ageGroup.map((item, index) => (
                          <div key={index} className="list-spans">
                            <span className="font-list">{item}</span>
                            <IconX
                              className="icon-x-list"
                              onClick={() =>
                                handleRemoveArray(index, "ageGroup")
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Col xs={12}>
                      <Form.Group className="mb-4" controlId="address">
                        <Form.Label className="label-form">Address</Form.Label>
                        <Form.Control
                          className="size-16 py-3"
                          size="lg"
                          type="text"
                          name="address"
                          value={formData.address}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Label className="label-form">
                        Country <span className="label-star">*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Country select"
                        className="py-3"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        isInvalid={!!errors.country}
                      >
                        <option value="">Select</option>
                        {countries.map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.country}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <p className="text-center p-3 mk-footer-copyright">
                      Your information will be processed according to our
                      Privacy Policy. By clicking above, you authorize SCool.Ai
                      to contact and send you marketing communications.
                    </p>
                    <button className="main-button weight-600" type="submit">
                      Signup Trial
                    </button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Logos />
    </>
  );
}

export default TrialAccount;
