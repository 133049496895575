import Accordion from "react-bootstrap/Accordion";

function Faq() {
  return (
    <>
      <p className="size-40 weight-700">FAQs</p>
      <Accordion
        defaultActiveKey="0"
        className="component-bottom-margin-2 remove-design"
        flush
      >
        <Accordion.Item eventKey="0" className="remove-design">
          <Accordion.Header className="faq-question ">
            Is SCool.Ai cost effective?
          </Accordion.Header>
          <Accordion.Body className="faq-answer ">
            SCool.Ai offers cost-effective solutions tailored to the needs of
            organizations, providing value for investment while ensuring quality
            education delivery.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1" className="remove-design">
          <Accordion.Header className="faq-question ">
            How safe is our data with SCool.Ai?
          </Accordion.Header>
          <Accordion.Body className="faq-answer ">
            SCool.Ai prioritizes data security, implementing robust measures to
            safeguard user information and ensuring compliance with privacy
            regulations.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2" className="remove-design">
          <Accordion.Header className="faq-question ">
            How difficult is it to setup and run SCool.Ai?
          </Accordion.Header>
          <Accordion.Body className="faq-answer ">
            SCool.Ai offers user-friendly setup and operation processes,
            supported by comprehensive documentation and responsive customer
            support for a seamless experience.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3" className="remove-design">
          <Accordion.Header className="faq-question ">
            Where can I book a call with the SCool.Ai team?
          </Accordion.Header>
          <Accordion.Body className="faq-answer ">
            You can book a call with the SCool.Ai team through our website or by
            reaching out to our customer support team via email or phone.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4" className="remove-design">
          <Accordion.Header className="faq-question ">
            How does SCool.Ai save me money?
          </Accordion.Header>
          <Accordion.Body className="faq-answer ">
            SCool.Ai optimizes educational resources and processes, reducing
            operational costs while enhancing learning outcomes, ultimately
            leading to significant savings for organizations.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

export default Faq;
