import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IconArrowBadgeRightFilled } from "@tabler/icons-react";
import Image3 from "./assets/fm.jpg";
import HRM from "./assets/HRM.jpg";
import EET from "./assets/EET.jpg";
import FM from "./assets/fm.jpg";
import AIF from "./assets/AIF.jpg";
import STM from "./assets/STM.jpg";
import PEA from "./assets/PEA.jpg";
import CM from "./assets/CM.jpg";
import RTM from "./assets/RTM.jpg";

function OurSolutions({ tab = "Financial Management" }) {
  const [activeTab, setActiveTab] = useState(tab);

  useEffect(() => {
    setActiveTab(tab);
  }, [tab]);

  useEffect(() => {
    const images = [FM, HRM, EET, STM, PEA, RTM, CM, AIF];
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);  

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <Container fluid className="special-bg-3" style={{ minHeight: "1000px" }}>
      <Container fluid="xxl">
        <Row className="d-flex justify-content-center content-bottom">
          <p className="subheading text-center mb-3">All-in-One Solution</p>
          <h3 className="text-center size-40 weight-700 second-color line-height-120">
            Empowering & Transforming Educational with AI
          </h3>
          <p className="heading-paragraph">
            SCool.Ai as a one-stop shop, Offering AI-integrated solutions that
            automate your school’s end-to-end workflows, from student admissions
            to alumni management.
          </p>
        </Row>
        <Row className="content-bottom">
          <Col xs={12} md={3} className="my-3">
            <div className="verticle-tabs menu-top-margin responsive-align mb-5">
              <div
                className={`verticle-tab-label text-center-resp ${
                  activeTab === "Financial Management" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Financial Management")}
              >
                <p>Financial Management</p>
              </div>
              <div
                className={`verticle-tab-label text-center-resp ${
                  activeTab === "Human Resource Management" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Human Resource Management")}
              >
                <p>HR Management</p>
              </div>
              <div
                className={`verticle-tab-label text-center-resp ${
                  activeTab === "Employee Efficiency Tracking" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Employee Efficiency Tracking")}
              >
                <p>Employee Efficiency Tracking</p>
              </div>
              <div
                className={`verticle-tab-label text-center-resp ${
                  activeTab === "Student Tracking & Monitoring" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Student Tracking & Monitoring")}
              >
                <p>Student Tracking & Monitoring</p>
              </div>
              <div
                className={`verticle-tab-label text-center-resp ${
                  activeTab === "Parent Engagement App" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Parent Engagement App")}
              >
                <p>Parent Engagement App</p>
              </div>
              <div
                className={`verticle-tab-label text-center-resp ${
                  activeTab === "Real-time Monitoring" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Real-time Monitoring")}
              >
                <p>Real-time Monitoring</p>
              </div>
              <div
                className={`verticle-tab-label text-center-resp ${
                  activeTab === "Compliance Management" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Compliance Management")}
              >
                <p>Compliance Management</p>
              </div>
              <div
                className={`verticle-tab-label text-center-resp ${
                  activeTab === "AI-Powered Features" ? "active" : ""
                }`}
                onClick={() => handleTabClick("AI-Powered Features")}
              >
                <p>AI-Powered Features</p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={9}>
            {/* <div
              style={{ display: "flex", flexDirection: "column", gap: "30px" }}
              className="body-os"
            >
              <p className="header-os">{activeTab}</p>
              {activeTab === "Financial Management" && (
                <p className="detail-os">
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Automated budgeting and forecasting tools.
                  </li>
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Real-time financial reporting and analytics
                  </li>
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Streamlined billing and invoicing systems
                  </li>
                </p>
              )}
              {activeTab === "Human Resource Management" && (
                <p className="detail-os">
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Digital HR workflows for recruitment, onboarding, and staff
                    management
                  </li>
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Employee performance and development tracking.
                  </li>
                </p>
              )}
              {activeTab === "Employee Efficiency Tracking" && (
                <p className="detail-os">
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Tools for monitoring teacher attendance and performance.
                  </li>
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Analytics for evaluating teaching methods and outcomes.
                  </li>
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Professional development modules tailored to individual
                    educator needs.
                  </li>
                </p>
              )}
              {activeTab === "Student Tracking & Monitoring" && (
                <p className="detail-os">
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Real-time attendance tracking with notifications for
                    unexplained absences.
                  </li>
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Emergency alert systems and child safety protocols.
                  </li>
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Geofencing and secure campus management features.
                  </li>
                </p>
              )}
              {activeTab === "Parent Engagement App" && (
                <p className="detail-os">
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Mobile platform for parents to view academic progress,
                    attendance, and school notifications.
                  </li>
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Direct communication channels between teachers and parents.
                  </li>
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Scheduling tools for parent-teacher conferences and school
                    events.
                  </li>
                </p>
              )}
              {activeTab === "Real-time Monitoring" && (
                <p className="detail-os">
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Comprehensive dashboards for school performance metrics.
                  </li>
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Custom reports on student achievement, resource allocation,
                    and more.
                  </li>
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Data integration from various sources for holistic views.
                  </li>
                </p>
              )}
              {activeTab === "Compliance Management" && (
                <p className="detail-os">
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Automated tools to ensure adherence to educational standards
                    and regulations.
                  </li>
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Document management systems for handling permissions, health
                    records, and other compliance-related paperwork.
                  </li>
                </p>
              )}
              {activeTab === "AI-Powered Features" && (
                <p className="detail-os">
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Automated report generation using Artificial Intelligence.
                  </li>
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    AI will enhance your institue productivity by 100% and save
                    cost.
                  </li>
                  <li>
                    {" "}
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    AI based automated management and planning.
                  </li>
                </p>
              )}
            </div> */}
            <div className="d-flex justify-content-center align-items-center">
              <div>
                {activeTab === "Financial Management" && (
                  <img
                    src={FM}
                    alt="FM"
                    className="img-fluid image-shadow-2"
                    style={{ maxWidth: "100%", height: "auto", borderRadius:'20px' }}
                  />
                )}
                {activeTab === "Human Resource Management" && (
                  <img
                    src={HRM}
                    alt="HRM"
                    className="img-fluid image-shadow-2"
                    style={{ maxWidth: "100%", height: "auto", borderRadius:'20px' }}
                  />
                )}
                {activeTab === "Employee Efficiency Tracking" && (
                  <img
                    src={EET}
                    alt="EET"
                    className="img-fluid image-shadow-2"
                    style={{ maxWidth: "100%", height: "auto", borderRadius:'20px' }}
                  />
                )}
                {activeTab === "Student Tracking & Monitoring" && (
                  <img
                    src={STM}
                    alt="STM"
                    className="img-fluid image-shadow-2"
                    style={{ maxWidth: "100%", height: "auto", borderRadius:'20px' }}
                  />
                )}
                {activeTab === "Parent Engagement App" && (
                  <img
                    src={PEA}
                    alt="PEA"
                    className="img-fluid image-shadow-2"
                    style={{ maxWidth: "100%", height: "auto", borderRadius:'20px' }}
                  />
                )}
                {activeTab === "Real-time Monitoring" && (
                  <img
                    src={RTM}
                    alt="RTM"
                    className="img-fluid image-shadow-2"
                    style={{ maxWidth: "100%", height: "auto", borderRadius:'20px' }}
                  />
                )}
                {activeTab === "Compliance Management" && (
                  <img
                    src={CM}
                    alt="CM"
                    className="img-fluid image-shadow-2"
                    style={{ maxWidth: "100%", height: "auto", borderRadius:'20px' }}
                  />
                )}{" "}
                {activeTab === "AI-Powered Features" && (
                  <img
                    src={AIF}
                    alt="AIF"
                    className="img-fluid image-shadow-2"
                    style={{ maxWidth: "100%", height: "auto", borderRadius:'20px' }}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default OurSolutions;
