import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import LogoSlider from "./LogoSlider";
import logo1 from "./assets/school1.png";
import logo2 from "./assets/school2.png";
import logo3 from "./assets/school3.png";
import logo4 from "./assets/school4.png";
import logo5 from "./assets/school5.png";

function Logos() {
  const logos = [logo1, logo2, logo3, logo4, logo5];
  return (
    <Container fluid="xxl" className="component-bottom-margin">
      <Row>
        <div className="logo-slider">
          <p className="text-center size-40 second-color line-height-120 extrabold-font">
            Transforming Education with
            <span className="main-color extrabold-font"> AI-Driven </span>
            Solutions
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <p
              className="d-flex justify-content-center align-items-center size-18 main-color cursor-pointer text-center w-100"
              style={{ maxWidth: "900px", marginBottom: "30px" }}
            >
              Explore how School.ai is reshaping educational experiences
              globally
            </p>
          </div>
          <LogoSlider logos={logos} />
        </div>
      </Row>
    </Container>
  );
}

export default Logos;
