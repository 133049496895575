import React, { useState, useEffect, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import {
  IconMenu2,
  IconX,
  IconChevronDown,
  // IconChevronUp,
} from "@tabler/icons-react";
import Image1 from "./icon.png";
import HeaderMenu from "./headerComponents/headerMenu";

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  // const [close, setClose] = useState(true);
  const [open, setOpen] = useState(false);
  const [toggleOne, setToggleOne] = useState(false);
  const headerRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const handleClose = () => {
  //   setClose(false);
  // };

  const handleToggle = () => {
    setToggleOne(!toggleOne);
  };

  const handleClickOutside = (event) => {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      setToggleOne(false);
    }
  };

  useEffect(() => {
    if (toggleOne) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [toggleOne]);

  useEffect(() => {
    setToggleOne(false);
  }, [location]);

  return (
    <>
      <div
        className={`header d-flex justify-content-between align-items-center responsive-header ${
          isSticky ? "sticky-header" : ""
        }`}
        ref={headerRef}
      >
        <Container fluid="xxl">
          <div className="d-flex justify-content-between align-items-center margin-top-resp">
            <Link
              to="/"
              style={{ textDecoration: "none", listStyle: "none" }}
              className="width-fix-big-logo"
            >
              <div
                style={{
                  fontSize: "24px",
                  textDecoration: "none",
                  listStyle: "none",
                }}
                className="main-color weight-700"
              >
                <img src={Image1} alt="logo" style={{ width: "190px" }} />
              </div>
            </Link>
            <div className="d-flex gap-5 display-none-resp ">
              <div
                className={`cursor-pointer nav-item ${
                  location.pathname === "/products" ? "active-nav" : ""
                } ${toggleOne ? "active-nav" : ""}`}
                onClick={handleToggle}
              >
                Features
                <IconChevronDown style={{ width: "20px", marginLeft: "5px" }} />
              </div>
              <Link
                to="/pricing"
                className={`cursor-pointer nav-item ${
                  location.pathname === "/pricing" ? "active-nav" : ""
                }`}
              >
                Pricing
              </Link>
              <Link
                to="/about"
                className={`cursor-pointer nav-item ${
                  location.pathname === "/about" ? "active-nav" : ""
                }`}
              >
                About
              </Link>
            </div>
            <div className="d-flex align-items-center">
              <div className="width-fix-big">
                <Link to="/trial-account">
                  <button className="main-button-reverse weight-600 book-demo-resp display-none-resp">
                    Free Trial
                  </button>
                </Link>
                <Link to="/book-demo">
                  <button className="main-button weight-600 book-demo-resp">
                    Demo
                  </button>
                </Link>
              </div>
              {!open ? (
                <IconMenu2
                  className="mx-3 res-menu-button"
                  onClick={() => setOpen(!open)}
                />
              ) : (
                <IconX
                  className="mx-3 res-menu-button"
                  onClick={() => setOpen(!open)}
                />
              )}
            </div>
          </div>
        </Container>
        {open && (
          <div
            className={`d-flex gap-5 res-menu-item height-nav-resp ${
              isSticky ? "sticky-header" : ""
            }`}
          >
            <Link
              to="/products"
              className={`cursor-pointer nav-item ${
                location.pathname === "/products" ? "active-nav" : ""
              }`}
            >
              Products
            </Link>
            <Link
              to="/pricing"
              className={`cursor-pointer nav-item ${
                location.pathname === "/pricing" ? "active-nav" : ""
              }`}
            >
              Pricing
            </Link>
            <Link
              to="/about"
              className={`cursor-pointer nav-item ${
                location.pathname === "/about" ? "active-nav" : ""
              }`}
            >
              About
            </Link>
            {/* <Link
              to="/trial-account"
              className={`cursor-pointer nav-item ${
                location.pathname === "/trial-account" ? "active-nav" : ""
              }`}
            >
              Signup Trial
            </Link> */}
          </div>
        )}
        <div className="display-none-resp">
          <HeaderMenu display={toggleOne} />
        </div>
      </div>
    </>
  );
};

export default Header;
