import React from "react";
import Modal from "react-bootstrap/Modal";
import apple from "./assets/apple.png";
import playstore from "./assets/playstore.png";

function LinkModal({ show, handleClose }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="custom-modal"
    >
      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <a
          href="https://shorturl.at/Qh98G"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={playstore}
            style={{ maxWidth: "250px", marginBottom: "20px" }}
            alt="playstore"
          />
        </a>
        <a
          href="https://apps.apple.com/pk/developer/gis-system-ug-haftungsbeschrankt/id1435009869"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={apple} style={{ maxWidth: "250px" }} alt="apple-store" />
        </a>
      </Modal.Body>
    </Modal>
  );
}

export default LinkModal;
