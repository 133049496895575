import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
// eslint-disable-next-line
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import MainPage from "./components/pages/mainPage";
import Header from "./components/header/header";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/css/main.css";
import Impressum from "./components/pages/impressum";
import Products from "./components/pages/products";
import BookDemo from "./components/pages/bookDemo";
import Footer from "./components/pages/pageComponents/footer";
import About from "./components/pages/about";
import Pricing from "./components/pages/pricing";
import TrialAccount from "./components/pages/trialAccount";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [showSpinner, setShowSpinner] = useState(true);
  // const location = useLocation();
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {showSpinner ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner animation="border" className="spinner" />
        </div>
      ) : (
        <>
          <ToastContainer />
          {/* {location.pathname !== '/impressum' && <Header />} */}
          <Header />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/products" element={<Products />} />
            <Route path="/about" element={<About />} />
            <Route path="/book-demo" element={<BookDemo />} />
            <Route path="/trial-account" element={<TrialAccount />} />
            <Route path="/pricing" element={<Pricing />} />
          </Routes>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
