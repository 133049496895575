import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image1 from "./assets/mudassir-khan.webp";
import Image2 from "./assets/lars-wegner.webp";
import Image3 from "./assets/florian-krugl.webp";
import Image4 from "./assets/abdullah-tariq.webp";
import Image5 from "./assets/ahmad-sadiq.webp";
import Image6 from "./assets/m-aamir.webp";
import Image7 from "./assets/umar-zaryab-sadiq.webp";
import Image8 from "./assets/haroon.webp";

function Team() {
  return (
    <Container fluid="xxl" className="component-bottom-margin-2">
      <Row>
        <div className="component-bottom-margin-2">
          <h3 className="text-center size-40 weight-700 second-color line-height-120 ">
            Our Team
          </h3>
          <p className="heading-paragraph ">
            People with help of those SCool.Ai was made possible.
          </p>
        </div>
        <Col xs={12} md={3} className="pad-bottom-24">
          <div className="custom-card">
            <img src={Image1} className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="subtitle">Chief Executive Officer</p>
              <p className="post-head-2">Muddassir Khan</p>
            </div>
          </div>
        </Col>
        <Col xs={12} md={3} className="pad-bottom-24">
          <div className="custom-card">
            <img src={Image5} className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="subtitle">Chief Technology Officer</p>
              <p className="post-head-2">M. Ahmed Sadiq</p>
            </div>
          </div>
        </Col>
        <Col xs={12} md={3} className="pad-bottom-24">
          <div className="custom-card">
            <img src={Image3} className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="subtitle">Design Advisor (Pro Bono)</p>
              <p className="post-head-2">Florian Krügl</p>
            </div>
          </div>
        </Col>
        <Col xs={12} md={3} className="pad-bottom-24">
          <div className="custom-card">
            <img src={Image4} className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="subtitle">Full Stack Developer</p>
              <p className="post-head-2">Abdullah Tariq</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={3} className="pad-bottom-24">
          <div className="custom-card">
            <img src={Image2} className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="subtitle">Strategy Advisor (Pro Bono)</p>
              <p className="post-head-2">Lars Wegener</p>
            </div>
          </div>
        </Col>
        <Col xs={12} md={3} className="pad-bottom-24">
          <div className="custom-card">
            <img src={Image6} className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="subtitle">Quality Assurance</p>
              <p className="post-head-2">Aamir Shehzad</p>
            </div>
          </div>
        </Col>
        <Col xs={12} md={3} className="pad-bottom-24">
          <div className="custom-card">
            <img src={Image7} className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="subtitle">Design & Web</p>
              <p className="post-head-2">Umar Zaryab</p>
            </div>
          </div>
        </Col>
        <Col xs={12} md={3} className="pad-bottom-24">
          <div className="custom-card">
            <img src={Image8} className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="subtitle">Full Stack Developer</p>
              <p className="post-head-2">Muhammad Haroon</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Team;
