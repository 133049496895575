import React, { useEffect } from "react";
import Testimonial from "./pageComponents/testimonial";
import OurSolutions from "./pageComponents/ourSolutions";
import Blog from "./pageComponents/blog";
import Awards from "./pageComponents/awards";
import Hero from "./pageComponents/hero";
import Logos from "./pageComponents/logos";
import OurPlatform from "./pageComponents/ourPlatform";

function MainPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero />
      <Logos />
      <OurPlatform />
      <OurSolutions />
      <Testimonial />
      <Awards />
      <Blog />
    </>
  );
}

export default MainPage;
