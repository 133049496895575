import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Benefit() {
  const features = [
    { title: "Made by School for a School", detail: "This complete system is already up and runnning in our schools." },
    { title: "Save Money, Time & Energy", detail: "Invest your time in growth instead of management with our system." },
    { title: "AI Helps you in Decision Making", detail: "Our AI System helps you with day to day decisions backed by data." },
    { title: "Increased Security & Quality", detail: "Data is secure and is available readily for reporting." },
    { title: "Easy Installation", detail: "Our system is a turn key solution for your school network expansion." },
    { title: "Very Low Maintenance", detail: "No hassle of maintining system, our team is there to assist." },
    { title: "Independence from Internet", detail: "Make your own school network to stop worrying about privacy issues." },
    { title: "High Level Reporting", detail: "View performance at individual level (child, teacher, staff)." },
    { title: "Intuitive Usage", detail: "Minimum usage of resources with smart AI system." }
  ];

  return (
    <Container fluid="xxl" className="component-bottom-margin-2">
      <Row>
        <h3 className="size-40 weight-700">Features Detail</h3>
        <p className="size-18 component-bottom-margin-2">
          Enhance your learning platform to drive engagement, productivity, and connection with your learners.
        </p>
        {features.map((feature, index) => (
          <Col key={index} xs={12} md={4} className="pad-bottom-24">
            <div className="custom-card-price">
              <div className="card-body">
                <h3 className="main-color">{feature.title}</h3>
                <p className="d-flex align-items-center size-16 weight-500 my-4">
                  {feature.detail}
                </p>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Benefit;
