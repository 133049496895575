import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FeaturesPng from "./pageComponents/assets/feature.png";
import ScholarApp from "./pageComponents/assets/scholarapp.png";
import MentorApp from "./pageComponents/assets/mentorapp.png";
import InstituteApp from "./pageComponents/assets/instituteapp.png";
import OurSolutions from "./pageComponents/ourSolutions";
// import BookDemoComp from "./pageComponents/bookDemoComp";
import ProductComp from "./pageComponents/productComp";
import { IconArrowRight } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LinkModal from "./pageComponents/linkModal";

function Products() {
  const location = useLocation();
  const scholarRef = useRef(null);
  const mentorRef = useRef(null);
  const instituteRef = useRef(null);
  const solutionsRef = useRef(null);
  const [activeTab, setActiveTab] = useState("Financial Management");
  const [modalShow, setModalShow] = useState(false);
  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const hash = decodeURIComponent(location.hash);
    if (hash === "#ScholarApp" && scholarRef.current) {
      scholarRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (hash === "#MentorApp" && mentorRef.current) {
      mentorRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (hash === "#InstituteApp" && instituteRef.current) {
      instituteRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (hash.startsWith("#Solutions")) {
      const hashParts = hash.split("-");
      if (hashParts.length > 1) {
        const tabValue = hashParts.slice(1).join("-");
        setActiveTab(tabValue);
        solutionsRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/book-demo");
  };
  return (
    <>
      <LinkModal show={modalShow} handleClose={handleClose} />
      <div className="special-bg-3 component-bottom-margin-2">
        <Container fluid="xxl">
          <Row>
            <Col xs={12} md={6}>
              <div className="py-5 px-5">
                <p className="subheading mb-3">Scool AI</p>
                <p className="d-flex hero-font extrabold-font weight-700 font-color-primary">
                  Discover Our Suite of Apps Tailored to Revolutionize Education
                </p>
                <p className="detail">
                  SCool.Ai offers specialized applications designed to cater to
                  the unique needs of students, parents, teachers, and
                  educational institutions.
                </p>
                <p className="detail">
                  Explore our apps and see how they can enhance your educational
                  experience and management.
                </p>
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <div>
                <img
                  src={FeaturesPng}
                  alt="image1"
                  className="img-fluid image-shadow-1"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid="xxl">
        <ProductComp />
        <div id="ScholarApp" ref={scholarRef} style={{ height: "100px" }}></div>
        <Row className="responsive-flex">
          <Col
            xs={12}
            md={6}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <img
                src={ScholarApp}
                alt="image1"
                className="img-fluid image-shadow-1"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <p className="subheading">Learning in Sync</p>
            <h3 className="size-40 weight-700 second-color line-height-120">
              Scholar App
            </h3>
            <p className="detail">
              For Students - Dive into a world of personalized learning where
              the curriculum is tailored to your pace and preferences. The
              Scholar App adapts to your learning style to help you achieve
              academic excellence.
            </p>
            <p className="detail">
              For Parents - Stay updated with real-time insights into your
              child's academic progress, attendance, and school notifications.
              Engage actively in your child’s educational journey from anywhere.
            </p>
            <Link onClick={handleShow} style={{ textDecoration: "none" }}>
              <p className="d-flex learn-more main-color cursor-pointer">
                Get Scholar app
                <span className="d-flex" style={{ marginLeft: "10px" }}>
                  <IconArrowRight className="right-arrow" stroke={2} />
                </span>
              </p>
            </Link>
          </Col>
        </Row>
        <div id="MentorApp" ref={mentorRef} style={{ height: "150px" }}></div>
        <Row>
          <Col xs={12} md={6} className="py-5">
            <p className="subheading">Empowering Educators</p>
            <h3 className="size-40 weight-700 second-color line-height-120">
              Mentor App
            </h3>
            <p className="detail">
              For Teachers - Elevate your teaching skills with Mentor App, your
              partner in professional development. Access a range of training
              modules designed to enhance your instructional techniques and
              classroom management skills.
            </p>
            <Link onClick={handleShow} style={{ textDecoration: "none" }}>
              <p className="d-flex learn-more main-color cursor-pointer">
                Get Mentor App
                <span className="d-flex" style={{ marginLeft: "10px" }}>
                  <IconArrowRight className="right-arrow" stroke={2} />
                </span>
              </p>
            </Link>
          </Col>
          <Col
            xs={12}
            md={6}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <img
                src={MentorApp}
                alt="image1"
                className="img-fluid image-shadow-2"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          </Col>
        </Row>
        <div
          id="InstituteApp"
          ref={instituteRef}
          style={{ height: "150px" }}
        ></div>
        <Row className="component-bottom-margin responsive-flex">
          <Col
            xs={12}
            md={6}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <img
                src={InstituteApp}
                alt="image1"
                className="img-fluid image-shadow-1"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          </Col>
          <Col xs={12} md={6} className="py-5">
            <p className="subheading">Seamless School Management</p>
            <h3 className="size-40 weight-700 second-color line-height-120">
              Institute App
            </h3>
            <p className="detail">
              For Schools and Colleges - Streamline every aspect of your
              institution's operations, from attendance and grading to
              compliance management. Institute App provides robust tools to
              handle administrative tasks efficiently, allowing you to focus on
              educational excellence.
            </p>
            <Link onClick={handleShow} style={{ textDecoration: "none" }}>
              <p className="d-flex learn-more main-color cursor-pointer">
                Get Institute App
                <span className="d-flex" style={{ marginLeft: "10px" }}>
                  <IconArrowRight className="right-arrow" stroke={2} />
                </span>
              </p>
            </Link>
          </Col>
        </Row>
      </Container>
      <div id="Solutions" ref={solutionsRef}></div>

      <div className="component-bottom-margin-2">
        <OurSolutions tab={activeTab} />
      </div>
      <Container fluid="xxl">
        <Row className="component-bottom-margin-2 mx-1">
          <div className="special-bg px-3">
            <h3 className="text-center size-40 weight-700 second-color margin-1 line-height-120">
              Ready to Transform Your Educational Experience?
            </h3>
            <div className="d-flex justify-content-center align-items-center text-color size-18 margin-2">
              <p className="text-center" style={{ maxWidth: "800px" }}>
                Join the countless educators, students, and parents who are
                benefiting from our tailored solutions. Get in touch with us
                today for a free demo and see how our apps can meet your needs.
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <button
                className="main-button-reverse weight-600"
                onClick={handleClick}
              >
                Schedule a Free Demo Now
              </button>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Products;
