import React from "react";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";

function BookDemoComp() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/book-demo");
  };

  return (
    <Row className="component-bottom-margin-2 mx-1">
      <div className="special-bg px-3">
        <h3 className="text-center size-40 weight-700 second-color margin-1 line-height-120">
          Our Vision
        </h3>
        <div className="d-flex justify-content-center align-items-center text-color size-18 margin-2">
          <p className="text-center" style={{ maxWidth: "800px" }}>
            To lead the global education revolution by integrating AI into every
            aspect of learning. By 2044, we envision a world where our AI-driven
            platforms empower educators and students in every corner of the
            globe, fostering a community where technology and education converge
            to unlock human potential.
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <button
            className="main-button-reverse weight-600"
            onClick={handleClick}
          >
            Embark on Our Journey!
          </button>
        </div>
      </div>
    </Row>
  );
}

export default BookDemoComp;
