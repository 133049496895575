import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  IconBrandLinkedin,
  IconBrandFacebook,
  IconBrandInstagram,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <Container fluid className="special-bg-4">
      <Container fluid="xxl">
        <Row>
          <Col xs={12} md={7} className="mt-5">
            <h3 className="size-40 weight-700 second-color line-height-120">
              SCool.Ai
            </h3>
            <p className="mk-footer-copyright">
              Copyright © {new Date().getFullYear()} GIS System UG
              (haftungsbeschränkt). All rights reserved. SCool.Ai is either a
              registered trademark or trademark of SCool.Ai LLC. <br />
              Address: Zum Marxbauer 3a, 82291 Mammendorf Bei Munich
              <br />
              Europe: 0049-173-712-1854 <br />
              Asia: 0092-333-910-1002 <br />
              Contact Email: info@SCool.Ai
            </p>
          </Col>
          <Col
            xs={12}
            md={5}
            className="d-flex justify-content-end align-items-end mb-3 flex-column mt-5"
          >
            <div className="d-flex justify-content-end align-items-end mb-4 gap-4">
              <a href="https://www.linkedin.com/company/scool-ai/" target="_blank" rel="noopener noreferrer">
                <IconBrandLinkedin className="icon" stroke={1.5} size={30} />
              </a>
              <a href="https://www.facebook.com/scoolai/" target="_blank" rel="noopener noreferrer">
                <IconBrandFacebook className="icon" stroke={1.5} size={30} />
              </a>
              {/* <a href="https://twitter.com/scool_ai" target="_blank" rel="noopener noreferrer">
                <IconBrandX className="icon" stroke={1.5} size={30} />
              </a> */}
              <a href="https://www.instagram.com/scool.ai/" target="_blank" rel="noopener noreferrer">
                <IconBrandInstagram className="icon" stroke={1.5} size={30} />
              </a>
            </div>
            <div className="d-flex justify-content-end align-items-end mb-3 menu-footer-parent">
              <ul className="menu-footer d-flex gap-4">
                <li>
                  <Link to="/impressum" className="menu-footer">
                    Impressum
                  </Link>
                </li>
                <li className="menu-footer">Cookie Policy </li>
                <li className="menu-footer">Privacy Policy</li>
                <li className="menu-footer">Compliance </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Footer;
