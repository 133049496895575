import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Price from "./pageComponents/price";
import Logos from "./pageComponents/logos";
import Benefit from "./pageComponents/benefits";
import Faq from "./pageComponents/faq";

function Pricing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container fluid="xxl">
        <Row className="component-bottom-margin-2 mt-5">
          <div className="py-5 px-5">
            <p className="subheading mb-3 text-center">Pricing</p>
            <h3 className="d-flex hero-font weight-700 text-center justify-content-center">
              An Investment In Knowledge Pays The Best Interest
            </h3>
            <p className="size-18 text-center">
              Empower your educational environment with SCool.Ai’s comprehensive
              suite of apps. Choose the plan that best fits your needs and
              budget.
            </p>
          </div>
        </Row>
        <Price />
        <Benefit />
        <Logos />
        <Faq />
      </Container>
    </>
  );
}

export default Pricing;
