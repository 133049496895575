import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import VideoSource from "./assets/hero.mp4";
import TextAnimation from "./textAnimation";
import { Link } from "react-router-dom";

function Hero() {
  const textArray = ["education", "institute", "students", "teachers"];
  const duration = 2000;
  return (
    <Container fluid="xxl" className="component-bottom-margin component-bottom-margin-s">
      <Row className="hero-size">
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center"
        >
          <div
            style={{ minWidth: "470px" }}
            className="py-5 px-3 min-width-resp"
          >
            <div
              className="d-flex hero-font weight-700 second-color extrabold-font"
            >
              Empower
              <span className="main-color ml-20px extrabold-font">
                <TextAnimation textArray={textArray} duration={duration} />
              </span>
            </div>
            <div className="d-flex hero-font weight-700 second-color mb-3 extrabold-font">
              with SCool.AI
            </div>
            <div className="d-flex hero-font-2 weight-500 mb-3">
              Shaping Futures Globally - Pioneering Education with AI
              Innovation.
            </div>
            <Link to="/book-demo" className="cursor-pointer nav-item">
              <button className="main-button weight-600 mb-3 w-100-resp">
                Book A Demo
              </button>
            </Link>
          </div>
        </Col>
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center"
        >
          <div>
            <video
              src={VideoSource}
              autoPlay
              loop
              muted
              className="img-fluid image-shadow-2"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Hero;
