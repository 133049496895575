import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import blog from "./assets/blog1.png";
import blog2 from "./assets/blog1.png";

function Blog() {
  return (
    <Container fluid="xxl" className="component-bottom-margin-2">
      <Row>
        <h3 className="text-center size-40 weight-700 second-color line-height-120 ">
          Recent Updates and Resources
        </h3>
        <div className="d-flex justify-content-center align-items-center">
          <p
            className="d-flex justify-content-center align-items-center component-bottom-margin-2 size-18 weight-500 main-color cursor-pointer text-center w-100"
            style={{ maxWidth: "900px"}}
          >
            Stay up-to-date with the latest developments and insights in
            educational technology.
          </p>
        </div>
        <Col xs={12} md={3} className="pad-bottom-24">
          <div className="custom-card">
            <img src={blog} className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="subtitle">Update 102</p>
              <p className="post-head">
                Added Student Profiles with thier Bio Data!
              </p>
              {/* <p className="d-flex align-items-center size-16 weight-500 main-color my-4">
                Learn More
                <span className="d-flex" style={{ marginLeft: "10px" }}>
                  <IconArrowRight className="right-arrow" stroke={2} />
                </span>
              </p> */}
            </div>
          </div>
        </Col>
        <Col xs={12} md={3} className="pad-bottom-24">
          <div className="custom-card">
            <img src={blog2} className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="subtitle">Upcoming</p>
              <p className="post-head">
                New updated UI comming soon! Be ready to update!
              </p>
              {/* <p className="d-flex align-items-center size-16 weight-500 main-color my-4">
                Learn More
                <span className="d-flex" style={{ marginLeft: "10px" }}>
                  <IconArrowRight className="right-arrow" stroke={2} />
                </span>
              </p> */}
            </div>
          </div>
        </Col>
        <Col xs={12} md={3} className="pad-bottom-24">
          <div className="custom-card">
            <img src={blog} className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="subtitle">Fact</p>
              <p className="post-head">
                89% Schools are still using traditional way to get student
                attendance!
              </p>
              {/* <p className="d-flex align-items-center size-16 weight-500 main-color my-4">
                Learn More
                <span className="d-flex" style={{ marginLeft: "10px" }}>
                  <IconArrowRight className="right-arrow" stroke={2} />
                </span>
              </p> */}
            </div>
          </div>
        </Col>
        <Col xs={12} md={3} className="pad-bottom-24">
          <div className="custom-card">
            <img src={blog2} className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="subtitle">Update 101</p>
              <p className="post-head">
                New AI Model Released! Update your app now!
              </p>
              {/* <p className="d-flex align-items-center size-16 weight-500 main-color my-4">
                Learn More
                <span className="d-flex" style={{ marginLeft: "10px" }}>
                  <IconArrowRight className="right-arrow" stroke={2} />
                </span>
              </p> */}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Blog;
