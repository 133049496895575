import React from "react";

function Impressum() {
  return (
    <body className="m-5">
      Impressum Informationspflichten nach dem TMG: Einzelshandelsfirma "GIS
      System UG"
      <br />
      <br />
      Inhaber Herr Muddassir Gohar Khan
      <br />
      <br />
      GIS System UG (haftungsbeschrÃ¤nkt) <br />
      Mammendorf HRB 268541 <br />
      Address: Zum Marxbauer 3a, 82291 Mammendorf Bei Munich <br />
      0049-173-712-1854 <br />
      <br />
      <br />
      Email: muddassir.khan@gissystem.eu <br />
      <br />
      Umsatzsteuer-Identifikationsnummer gem. Â§ 27 a Umsatzsteuergesetz
      DE309535874
      <br />
      <br />
      Verantwortlicher i.S.d Â§ TMG: Herr Muddassir Gohar Khan
    </body>
  );
}

export default Impressum;
